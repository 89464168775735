import type { RouteRecordRaw } from 'vue-router'

const appRoutes: RouteRecordRaw[] = [
  {
    path: '/',
    component: () => import('@/layouts/app/layout-app.vue'),
    children: [
      {
        path: '',
        name: 'home',
        component: () => import('@/views/home/page-home.vue'),
      },
      {
        path: 'lobby',
        name: 'lobby',
        component: () => import('@/views/home/page-lobby.vue'),
      },
      {
        path: 'play',
        name: 'play',
        component: () => import('@/views/play/page-play.vue'),
      },
      {
        path: 'setting',
        name: 'setting',
        component: () => import('@/views/setting/page-setting.vue'),
      },
    ],
  },
]

export { appRoutes }
