import type { RouteRecordRaw } from 'vue-router'

const documentationRoutes: RouteRecordRaw[] = [
  {
    path: '/documentation',
    component: () => import('@/layouts/documentation/layout-documentation.vue'),
    children: [
      {
        path: '',
        name: 'documentation',
        component: () => import('@/views/documentation/page-documentation.vue'),
      },
    ],
  },
]

export { documentationRoutes }
