import type { App } from 'vue'
import AppAnimalIcon from './app-animal-icon.vue'
import AppAnimatedDot from './app-animated-dot.vue'
import AppCodeBlock from './app-code-block.vue'
import AppDadu from './app-dadu.vue'
import AppErrorMsg from './app-error-msg.vue'
import AppImg from './app-img.vue'
import AppNotice from './app-notice.vue'
import { useButton } from './button'
import FaIcon from './fa-icon.vue'
import { useInput } from './input'
import useModal from './modal'

export default {
  install (app: App) {
    app.use(useModal)
    app.use(useInput)
    app.use(useButton)

    app.component('AppErrorMsg', AppErrorMsg)
    app.component('AppCodeBlock', AppCodeBlock)
    app.component('AppDadu', AppDadu)
    app.component('AppAnimatedDot', AppAnimatedDot)
    app.component('AppImg', AppImg)
    app.component('AppNotice', AppNotice)
    app.component('AppAnimalIcon', AppAnimalIcon)
    app.component('FaIcon', FaIcon)
  },
}

declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    FaIcon: typeof FaIcon,
    AppNotice: typeof AppNotice,
    AppErrorMsg: typeof AppErrorMsg,
    AppDadu: typeof AppDadu,
    AppCodeBlock: typeof AppCodeBlock,
    AppAnimatedDot: typeof AppAnimatedDot,
    AppImg: typeof AppImg,
    AppAnimalIcon: typeof AppAnimalIcon,
  }
}
