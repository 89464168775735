import { createRouter, createWebHistory } from 'vue-router'
import { appRoutes } from './app-routes'
import { documentationRoutes } from './documentation-routes'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    ...appRoutes,
    ...documentationRoutes,
  ],
})

export default router
