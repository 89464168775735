<script setup lang="ts">
  const emit = defineEmits<{
    (e: 'click'): void,
  }>()
</script>

<template>
  <div class="absolute right-2 top-3 lg:right-4 lg:top-5">
    <button
      class="flex-center size-8 rounded-full text-gray-500 hover:bg-gray-200"
      @click="emit('click')">
      <fa-icon icon="ic:sharp-close" />
    </button>
  </div>
</template>
