<script setup lang="ts">
  import { watchDebounced } from '@vueuse/core'

  const modelValue = defineModel<string>({ default: '' })

  defineProps<{
    placeholder?: string,
    loading?: boolean,
  }>()

  const emit = defineEmits<{
    (e: 'submit', value: string): void,
  }>()

  watchDebounced(
    modelValue,
    () => {
      emit('submit', modelValue.value)
    },
    { debounce: 500 },
  )
</script>

<template>
  <app-input
    v-model="modelValue"
    type="search"
    :placeholder="placeholder ?? 'Cari...'"
    @keyup.enter="emit('submit', modelValue)">
    <template #inline>
      <div class="flex-center absolute z-10 w-9 text-sm text-gray">
        <fa-icon icon="i-far-magnifying-glass" />
      </div>
      <div class="absolute inset-y-0 right-2 z-10 flex items-center text-xs text-gray">
        <div
          v-if="loading"
          class="flex-center h-6 w-6">
          <fa-icon
            icon="i-fas-circle-notch"
            class="animate-spin" />
        </div>
        <app-button
          v-else-if="modelValue"
          class="flex-center h-6 w-6 rounded-full hover:bg-gray-200"
          @click="modelValue = ''">
          <fa-icon icon="i-fas-xmark" />
        </app-button>
      </div>
    </template>
  </app-input>
</template>

<style lang="postcss" scoped>
  :deep() input {
    @apply pl-9 pr-8 placeholder-shown:pr-4;
  }
</style>
